import { REACT_APP_BASE_PATH } from "./baseUrl";

export const REGISTER_API = {
  AddStaff: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/add`, {
        method: "post",
        body: data,
        // headers: {
        //     "Content-Type": "application/json"
        // }
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  UpdateStaff: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/update`, {
        method: "post",
        body: data,
        // headers: {
        //     "Content-Type": "application/json"
        // }
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  UpdateStaffCredential: async (data) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/update/credential`,
        {
          method: "put",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  ImportStaff: async (data) => {
    // console.log(data.get("file"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/import`, {
        method: "post",
        body: data,
        // headers: {
        //     "Content-Type": "application/json"
        // }
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaff: async () => {
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/all-staff`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffById: async (id) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/details/${id}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffByStaffId: async (staff_id) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/detailbystaffid/${staff_id}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffDataBySearchKey: async (value) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/search-data?search=${value}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffRole: async () => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/all-role`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffDataByRole: async (role) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/data?role=${role}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetStaffCountByRole: async (data) => {
    // console.log(data);
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/countbyrole`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  ChangePassword: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/changepass`, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetAllTeacher: async () => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/allteacher`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetAllEmployee: async () => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/staff/allemployee`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  VerifyEmail: async (email) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/verifyemail/${email}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  SendOTPEmail: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/sendotpemail`,
        {
          method: "post",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  ResetPassword: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/staff/resetpassword`,
        {
          method: "post",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
};
