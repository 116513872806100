import React from "react";
import { IoArrowForwardCircleOutline,IoSpeedometerOutline  } from "react-icons/io5";
import { CNavGroup, CNavItem } from "@coreui/react";

const Sidebar_Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/student/dashboard",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "My Profile",
    to: "/student/profile",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Fees",
    to: "/student/getfees",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Class Timetable",
    to: "/student/timetable",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Apply Leave",
    to: "/student/leave",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Attendance",
    to: "/student/attendance",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Library",
    to: "/student/library",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Transport",
    to: "/student/transport",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavItem,
    name: "Hostel",
    to: "/student/hostel",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
];

export default Sidebar_Menu;
