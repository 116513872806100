import { REACT_APP_BASE_PATH } from "./baseUrl";

export const TEACHER_API = {
  Add: async (data) => {
    // console.log(data.get("photo"))
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/teacher/add`, {
        method: "post",
        body: data,
        // headers: {
        //     "Content-Type": "application/json"
        // }
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  ImportTeacher: async (data) => {
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/teacher/importdata`,
        {
          method: "post",
          body: data,
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  Get: async () => {
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/teacher/all`, {
        method: "get",
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  GetTeacherById: async (id) => {
    // console.log(id);
    try {
      const response = await fetch(
        `${REACT_APP_BASE_PATH}/teacher/details/${id}`,
        {
          method: "get",
        }
      );
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
  Update: async (data) => {
    try {
      const response = await fetch(`${REACT_APP_BASE_PATH}/section/update`, {
        method: "put",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const returnData = await response.json();
      return returnData;
    } catch (error) {
      return error;
    }
  },
};
