import React from "react";
import { IoArrowForwardCircleOutline,IoSpeedometerOutline  } from "react-icons/io5";
import { PiUsersThree } from "react-icons/pi";
import { FaRegCheckCircle,FaChartBar  } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { MdOutlineMenuBook } from "react-icons/md";
import { CNavGroup, CNavItem } from "@coreui/react";

const Sidebar_Menu = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/accountant/dashboard",
    icon: <IoSpeedometerOutline className="fs-5 nav-icon"/>,
  },
  {
    component: CNavGroup,
    name: "Fees Collection",
    icon: <PiUsersThree className="fs-5 nav-icon"/>,
    items: [
      {
        component: CNavItem,
        name: "Collect Fees",
        to: "/accountant/studentfee",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Fees Group",
        to: "/accountant/feegroup",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Fees Type",
        to: "/accountant/feetype",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Fees Reminder",
        to: "/accountant/feereminder",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Income",
    icon: <FaRegCheckCircle className="fs-5 nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Add Income",
        to: "/accountant/income",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Search Income",
        to: "/accountant/income/incomesearch",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Income Head",
        to: "/accountant/incomehead",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
    ],
  },
  {
    component: CNavGroup,
    name: "Human Resource",
    icon: <HiOutlineUserGroup className="fs-5 nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Staff Directory",
        to: "/teacher/staff/details",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
      {
        component: CNavItem,
        name: "Apply Leave",
        to: "/teacher/addleave",
        icon:<IoArrowForwardCircleOutline className="fs-5" />
      },
    ],
  },
];

export default Sidebar_Menu;
