import { REACT_APP_BASE_PATH } from "./baseUrl";

export const LOGIN_API = {
    Signin: async(data)=>{
        // console.log(data,REACT_APP_BASE_PATH)
        try{
            const response = await fetch(`${REACT_APP_BASE_PATH}/login`, {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    StudentLogin: async(data)=>{
        // console.log(data)
        try{
            const response = await fetch(`${REACT_APP_BASE_PATH}/student/login`, {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    TeacherLogin: async(data)=>{
        // console.log(data)
        try{
            const response = await fetch(`${REACT_APP_BASE_PATH}/teacher/login`, {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    },
    Signout: async()=>{
        // console.log(data)
        try{
            const response = await fetch(`${REACT_APP_BASE_PATH}/logout`, {
                method: "get",
            })
            const returnData = await response.json();
            return returnData;
        }
        catch(error){
            return error;
        }
    }
}