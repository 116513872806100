import React, { useState } from "react";
import "../../Css/password.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const updatePassword = (e)=>{
        e.preventDefault();
        if(!email){
            toast.error("Email field is required!", {
                position: toast.POSITION.TOP_RIGHT,
              });
        }
        else if(!password || !confirmPassword){
            toast.error("Password and Confirm Password required!", {
                position: toast.POSITION.TOP_RIGHT,
              });
        }
        else if(password != confirmPassword){
            toast.error("Password and Confirm Password must be same!", {
                position: toast.POSITION.TOP_RIGHT,
              });
        }
        else{
            console.log(email,password,confirmPassword)
        }
    }
  return (
    <>
    <ToastContainer />
      <section className="password-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-6 form-border">
            <h5 className="text-center">Update Password For Login</h5>
            <form className="form-inline" onSubmit={updatePassword}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Email..."
                  value={email}
                  onChange={((e)=>setEmail(e.target.value))}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName2">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password..."
                  value={password}
                  onChange={((e)=>setPassword(e.target.value))}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputName2">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password..."
                  value={confirmPassword}
                  onChange={((e)=>setConfirmPassword(e.target.value))}
                />
              </div>
              <button type="submit" className="btn btn-primary mx-auto d-block">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default AddPassword;
