import React, { useEffect, useState, useContext } from "react";
import "../../../Css/login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { LOGIN_API } from "../../../AllApi/loginApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "./LoginNavbar";

const Login = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    user.setOTPPage("false");
    user.setResetEmail("");
  }, []);
  // Login data change function
  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  // Login data submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!loginData.username) {
      toast.error("Username is required..", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!loginData.password) {
      toast.error("Password is required..", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      LOGIN_API.Signin(loginData).then((response) => {
        if (response.status === 200) {
          user.setCurrentUser(response.data.staff_id);
          user.setUserRole(response.data.role);
          user.setAuthToken(response.token);
          user.setMessage(response.message);
          localStorage.setItem("user-role", response.data.role);
          localStorage.setItem("iec-active-user", response.data.staff_id);
          navigate(`/${response.role}/dashboard`);
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    }
  };
  return (
    <div className="main">
      <ToastContainer />
      <LoginNavbar />
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="card shadow mt-4">
              <div className="card-title text-center border-bottom">
              <div className="mt-2">
                    <img src={ieclogo} width="80" height="80" style={{borderRadius:"50%"}} alt="Colege ERP" />
                </div>
                <h4 className="welcome_login p-2">
                  Login
                </h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control input-text bg-light"
                      id="username"
                      name="username"
                      onChange={handleChange}
                      value={loginData.username}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="input-text bg-light form-control"
                      onChange={handleChange}
                      value={loginData.password}
                    />
                  </div>
                  <div className="d-grid mb-3">
                    <button type="submit" className="btn text-light fw-bold">
                      Login
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-lg-12 col-md-12 mb-5 text-center">
                <NavLink
                  to="/forgotpassword"
                  color="link"
                  className="forgot-password "
                >
                  Forgot Password?
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
