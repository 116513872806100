import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
import { REGISTER_API } from "../AllApi/addStaffApi";

// routes config
import routes from "../Routes/AllRoutes";
// import teacherRoutes from "../Routes/teacherRoutes";

const AppContent = () => {

  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              );
            })}
            {/* <Route path="*" element={<Navigate to="/404"/>} /> */}
          </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
