import React, {
  Component,
  Suspense,
  useEffect,
  useState,
  createContext,
} from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Login from "./views/pages/login/Login";
import Page404 from "./views/pages/page404/Page404";
import DefaultLayout from "./layout/DefaultLayout";
import AddPassword from "./views/staff/AddPassword";
import UserContext from "./UserContext";
import ForgotPassword from "./views/pages/forgotPassword/ForgotPassword";
import ForgotPasswordForm from "./views/pages/forgotPassword/ForgotPasswordForm";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StudentLogin from "./views/pages/login/StudentLogin";
import GuardianLogin from "./views/pages/login/GuardianLogin";
import TeacherLogin from "./views/pages/login/TeacherLogin";

export const Loading = () => {
  return (
    <div className="spinner-box" id="Loading">
      <div className="circle-border">
        <div className="circle-core"></div>
      </div>
    </div>
  );
};
const App = () => {
  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("iec-active-user")
  );
  const [userRole, setUserRole] = useState(localStorage.getItem("user-role"));
  const [otp, setOTP] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [otpPage, setOTPPage] = useState("false");
  const [message, setMessage] = useState("");
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    if (message) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage("");
    }
  }, [message]);
  return (
    <>
      <ToastContainer />
      <UserContext.Provider
        value={{
          currentUser,
          setCurrentUser,
          userRole,
          setUserRole,
          otp,
          setOTP,
          resetEmail,
          setResetEmail,
          otpPage,
          setOTPPage,
          message,
          setMessage,
          setAuthToken,
          authToken,
        }}
      >
        <Suspense fallback={<div>Loading.........</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={currentUser ? `${userRole}/dashboard` : "/login"}
                />
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/teacher/login" element={<TeacherLogin />} />
            <Route path="/student/login" element={<StudentLogin />} />
            <Route path="/parent/login" element={<GuardianLogin />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/newpassword" element={<ForgotPasswordForm />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/staff/addpassword" element={<AddPassword />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </UserContext.Provider>
    </>
  );
};

export default App;
