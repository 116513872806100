import React, { useState, useContext } from "react";
import "../../../Css/login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import UserContext from "../../../UserContext";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPassword = () => {
  const { otp, setOTP, setResetEmail, resetEmail, otpPage, setOTPPage } =
    useContext(UserContext);
  const [email, setEmail] = useState("");
  const [enterOTP, setEnterOTP] = useState("");
  const [display, setDisplay] = useState(false);
  const [timerCount, setTimerCount] = useState(10);
  const navigate = useNavigate();

  const VerifyEmail = (e) => {
    e.preventDefault();
    if (otpPage === "true") {
      if (enterOTP) {
        if (parseInt(enterOTP) === otp) {
          setOTPPage("false");
          navigate("/newpassword");
        } else {
          toast.error(
            "The code you have entered is not correct, try again  re-send the code",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else {
        toast.error("Please enter verification code", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (email) {
        REGISTER_API.VerifyEmail(email).then((resp) => {
          if (resp.status === 200) {
            document.getElementById("email_success").style.display = "block";
            setDisplay(true);
            const OTP = Math.floor(Math.random() * 9000 + 1000);
            setOTP(OTP);
            setResetEmail(email);
            setOTPPage("true");
            const data = {
              otp: OTP,
              email: email,
            };
            REGISTER_API.SendOTPEmail(data);
            // Timer count
            var timeC = 10;
            var timer = setTimeout(countdown, 1000);
            function countdown() {
              timeC--;
              if (timeC >= 0) {
                setTimerCount((prev) => prev - 1);
                setTimeout(countdown, 1000);
              } else if (timeC < 0) {
                clearTimeout(timer);
                setDisplay(false);
              }
            }
          } else {
            document.getElementById("email_error").style.display = "block";
            document.getElementById("email_success").style.display = "none";
          }
        });
      } else {
        toast.error("Please enter your email", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const ResendOTP = () => {
    setTimerCount((prev) => prev + 10);
    const OTP = Math.floor(Math.random() * 9000 + 1000);
    setOTP(OTP);
    setOTPPage("true");
    const data = {
      otp: OTP,
      email: resetEmail,
    };
    REGISTER_API.SendOTPEmail(data);
    // Timer count
    setDisplay(true);
    var timeC = 10;
    var timer = setTimeout(countdown, 1000);
    function countdown() {
      timeC--;
      if (timeC > 0) {
        setTimerCount((prev) => prev - 1);
        setTimeout(countdown, 1000);
      } else if (timeC === 0) {
        setTimerCount(0);
        clearTimeout(timer);
        setDisplay(false);
      }
    }
  };

  return (
    <div className="main">
      <ToastContainer />
      <LoginNavbar />
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="middle-box text-center loginscreen animated fadeInDown">
              <form onSubmit={VerifyEmail}>
                {otpPage === "false" ? (
                  <div
                    style={{
                      backgroundColor: "white",
                      opacity: "90%",
                      borderRadius: 5,
                      padding: 45,
                    }}
                  >
                    <div className="mb-2">
                      <img
                        src={ieclogo}
                        width="80"
                        height="80"
                        style={{ borderRadius: "50%" }}
                        alt="Colege ERP"
                      />
                    </div>
                    <h4>
                      <b>FORGOT PASSWORD</b>
                    </h4>
                    <p />
                    <div className="form-group">
                      <input
                        id="rdbCollege"
                        type="radio"
                        name="rdbGN"
                        defaultChecked="checked"
                      />
                      <label htmlFor="rdbCollege">Administrator</label>
                      &nbsp; &nbsp;
                      <input id="rdbStudent" type="radio" name="rdbGN" />
                      <label htmlFor="rdbStudent">Teacher</label>
                      &nbsp; &nbsp;
                      <input id="rdbStudent" type="radio" name="rdbGN" />
                      <label htmlFor="rdbStudent">Student</label>
                      &nbsp; &nbsp;
                      <input id="rdbStudent" type="radio" name="rdbGN" />
                      <label htmlFor="rdbStudent">Guardian</label>
                    </div>
                    <div id="divDetails">
                      <div className="form-group">
                        Enter Username
                        <input
                          name="txtUsername"
                          type="text"
                          id="txtUsername"
                          className="form-control"
                          placeholder="Username"
                        />
                      </div>
                      <div className="d-grid mb-3">
                        <button type="submit" className="btn text-light">
                          Send OTP
                        </button>
                      </div>
                      {/* <div id="divlblSendCode" className="form-group">
                        <span id="lblSendCode" style={{ color: "Blue" }}>
                          Entered Username is incorrect or Email is not updated
                          against the entered username.
                        </span>
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: "white",
                      opacity: "90%",
                      borderRadius: 5,
                      padding: 45,
                    }}
                  >
                    <div className="mt-2">
                      <img
                        src={ieclogo}
                        width="80"
                        height="80"
                        style={{ borderRadius: "50%" }}
                        alt="Colege ERP"
                      />
                    </div>
                    <h4>
                      <b>FORGOT PASSWORD</b>
                    </h4>
                    <p />
                    <div id="divDetails">
                      <div className="form-group">
                        Enter OTP
                        <input
                          type="text"
                          name="enterOTP"
                          onChange={(e) => setEnterOTP(e.target.value)}
                          value={enterOTP}
                          className="form-control mb-4"
                          placeholder="OTP"
                        />
                      </div>
                      <div className="d-grid mb-3">
                        <button type="submit" className="btn text-light">
                          Submit OTP
                        </button>
                      </div>
                      {/* <div id="divlblSendCode" className="form-group">
                        <span id="lblSendCode" style={{ color: "Blue" }}>
                          Please enter correct OTP or resend OTP again.
                        </span>
                      </div> */}
                      <NavLink
                        to="#"
                        color="link"
                        className="forgot-password"
                        onClick={ResendOTP}
                      >
                        Didn't receive code?
                        <span
                          className="text-danger mx-1"
                          style={{ fontSize: "12px", fontStyle: "italic" }}
                        >
                          {display
                            ? `Resent OTP in ${timerCount}s`
                            : "Resend OTP"}
                        </span>
                      </NavLink>
                    </div>
                  </div>
                )}
              </form>
            </div>

            {/* <div className="card shadow mt-4">
              <div className="card-title text-center border-bottom">
                <p
                  className="email_success"
                  id="email_success"
                  style={{ display: "none" }}
                >
                  We have sent a verification code to your email.
                </p>
              </div>
              <div className="card-body">
                <form onSubmit={VerifyEmail}>
                  {otpPage === "false" ? (
                    <>
                      <label>Email</label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="email"
                          name="email"
                          className="input-text bg-light"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </CInputGroup>
                      <p
                        className="email_error"
                        id="email_error"
                        style={{ display: "none" }}
                      >
                        User with this email does not exist!
                      </p>
                      <CRow className="mt-3">
                        <CCol lg={12}>
                          <CButton
                            style={{ backgroundColor: "#f58924" }}
                            className="pt-2 pb-2 w-100 border-0 rounded-1"
                            type="submit"
                          >
                            Verify Email
                          </CButton>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <label>Verification code </label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="text"
                          name="enterOTP"
                          className="input-text bg-light"
                          onChange={(e) => setEnterOTP(e.target.value)}
                          value={enterOTP}
                        />
                      </CInputGroup>
                      <CRow className="mt-3">
                        <CCol lg={12}>
                          <CButton
                            style={{ backgroundColor: "#f58924" }}
                            className="pt-2 pb-2 w-100 border-0 rounded-1"
                            type="submit"
                          >
                            Verify Account
                          </CButton>
                        </CCol>
                        <CCol
                          lg={12}
                          className="mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            to="#"
                            color="link"
                            className="forgot-password"
                            onClick={ResendOTP}
                          >
                            Didn't receive code?
                            <span
                              className="text-danger mx-1"
                              style={{ fontSize: "12px", fontStyle: "italic" }}
                            >
                              {display
                                ? `Resent OTP in ${timerCount}s`
                                : "Resend OTP"}
                            </span>
                          </NavLink>
                        </CCol>
                      </CRow>
                    </>
                  )}
                </form>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* <div className=" container-fluid container-section">
        <CRow>
          <CCol md={4} className=" mx-auto d-block">
            <CForm onSubmit={VerifyEmail}>
              <CRow>
                <CCol md={12} sm={10} xs={10} className="login-section">
                  <p
                    className="email_success"
                    id="email_success"
                    style={{ display: "none" }}
                  >
                    We have sent a verification code to your email.
                  </p>
                  {otpPage === "false" ? (
                    <>
                      <label>Email</label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="email"
                          name="email"
                          className="input-text bg-light"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </CInputGroup>
                      <p
                        className="email_error"
                        id="email_error"
                        style={{ display: "none" }}
                      >
                        User with this email does not exist!
                      </p>
                      <CRow className="mt-3">
                        <CCol lg={12}>
                          <CButton
                            style={{ backgroundColor: "#f58924" }}
                            className="pt-2 pb-2 w-100 border-0 rounded-1"
                            type="submit"
                          >
                            Verify Email
                          </CButton>
                        </CCol>
                        <CCol lg={12} className="mt-2">
                          <NavLink
                            to="/login"
                            color="link"
                            className="forgot-password float-end"
                          >
                            Back to Login
                          </NavLink>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <label>Verification code </label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="text"
                          name="enterOTP"
                          className="input-text bg-light"
                          onChange={(e) => setEnterOTP(e.target.value)}
                          value={enterOTP}
                        />
                      </CInputGroup>
                      <CRow className="mt-3">
                        <CCol lg={12}>
                          <CButton
                            style={{ backgroundColor: "#f58924" }}
                            className="pt-2 pb-2 w-100 border-0 rounded-1"
                            type="submit"
                          >
                            Verify Account
                          </CButton>
                        </CCol>
                        <CCol
                          lg={12}
                          className="mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <NavLink
                            to="#"
                            color="link"
                            className="forgot-password"
                            onClick={ResendOTP}
                          >
                            Didn't receive code?
                            <span
                              className="text-danger mx-1"
                              style={{ fontSize: "12px", fontStyle: "italic" }}
                            >
                              {display
                                ? `Resent OTP in ${timerCount}s`
                                : "Resend OTP"}
                            </span>
                          </NavLink>
                          <NavLink
                            to="/login"
                            color="link"
                            className="forgot-password"
                          >
                            Back to Login
                          </NavLink>
                        </CCol>
                      </CRow>
                    </>
                  )}
                </CCol>
              </CRow>
            </CForm>
            <CRow className="mt-3"></CRow>
          </CCol>
        </CRow>
      </div> */}
    </div>
  );
};

export default ForgotPassword;
