import React from "react";
import "../Css/footer.css";

const AppFooter = () => {
  return (
      <div className="footers">
        <span style={{fontSize:"11px",float:"right",marginRight:"5px"}}>
          &copy;&nbsp;2024 IEC COLLEGE
        </span>
      </div>
  );
};

export default React.memo(AppFooter);
