import React, { useContext, useState } from "react";
import "../../../Css/login.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ieclogo from "../../../assets/images/ice-logo.jpeg";
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import UserContext from "../../../UserContext";
import { REGISTER_API } from "../../../AllApi/addStaffApi";
import LoginNavbar from "../login/LoginNavbar";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { resetEmail, setOTP, setResetEmail, setMessage } =
    useContext(UserContext);
  const [newPassword, setNewPassword] = useState("");

  const ResetPassword = (e) => {
    e.preventDefault();
    const data = {
      reset_password: newPassword,
      email: resetEmail,
    };
    REGISTER_API.ResetPassword(data).then(async (resp) => {
      if (resp.status === 200) {
        await setOTP("");
        await setResetEmail("");
        await setMessage(resp.message);
        navigate("/login");
      }
    });
  };
  return (
    <>
      <ToastContainer />
      {/* <LoginNavbar /> */}
      <div className="container-fluid">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="card shadow mt-4">
              <div className="card-body">
                <CForm onSubmit={ResetPassword}>
                  <CRow>
                    <CCol md={12} sm={10} xs={10} className="login-section">
                      <label>New Password</label>
                      <CInputGroup className="mb-2">
                        <CFormInput
                          type="text"
                          name="password"
                          className="input-text bg-light"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </CInputGroup>
                      <CRow className="mt-4">
                        <CCol lg={12}>
                          <CButton
                            style={{ backgroundColor: "#C21807" }}
                            className="pt-2 pb-2 w-100 border-0 rounded-1"
                            type="submit"
                          >
                            Reset Password
                          </CButton>
                        </CCol>
                        <CCol lg={12} className="mt-2">
                          <NavLink
                            to="/login"
                            color="link"
                            className="forgot-password float-end"
                          >
                            Log In
                          </NavLink>
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
